import Avatar1 from "../assets/Avatar1.png";
import Avatar2 from "../assets/Avatar2.JPG";


const dataAvatar = [
  {
    id: 1,
    image: Avatar1,
    title: "Ahmed Talaat",
    name: "Ahmed Talaat",
    review: "Aya's great at developing exactly what you want and on time. She's very supportive during and after delivery. She's my #1 go to!",
  },
  {
    id: 2,
    image: Avatar2,
    title: "Yasseen",
    name: "Yasseen",
    review:
      "Awesome website! All my clients gave me a good feedback about my business' website and how user-friendly it was! Thanks, Aya!",
  },
  
];

export default dataAvatar;
