import IMG1 from "../assets/Project1.jpg";
import IMG2 from "../assets/Project2.jpg";
import IMG3 from "../assets/Project3.jpg";
import IMG4 from "../assets/Project4.jpg";
import IMG5 from "../assets/Project5.jpg";
import IMG6 from "../assets/Project6.jpg";
import IMG7 from "../assets/project7.jpg";
import IMG8 from "../assets/Project8.png";

const dataArray = [
  {
    id: 8,
    image: IMG8,
    title: "Pick A Lock LLC",
    demo: "https://pickalockllc.net",
    // github: "none",
    tech: "Affinity Photo",
    tech2:"ReactJS",
    tech3: "Terminal",
    tech4: "React Bootstrap",
    tech5: "JavaScript",
    tech6: "Sass",
    tech7: "CSS3",
    tech8: "HTML5",
    tech9:"TailWind",
    tech10:"FontAwesome"
  },{
    id: 1,
    image: IMG1,
    title: "Old Portfolio",
    github: "https://github.com/aya7abbal/my-old-portfolio",
    demo: "https://aya7abbal.github.io/my-old-portfolio/",
    tech: "Affinity Photo",
    tech2:"Affinity Designer",
    tech3: "Github",
    tech4: "Bootstrap",
    tech5: "JavaScript",
    tech6: "Sass",
    tech7: "CSS3",
    tech8: "HTML5",
  },
  {
    id: 2,
    image: IMG2,
    title: "Developer's Portfolio",
    github: "https://www.github.com/react-portfolio",
    demo: "https://ahmedtalaaat.dev/",
    tech: "Github",
    tech2: "Bootstrap",
    tech3: "JavaScript",
    tech4: "Sass",
    tech5: "CSS3",
    tech6: "HTML5",
  },
  {
    id: 3,
    image: IMG3,
    title: "Sharjah's Government Back Office Platform",
    tech: "Bitbucket",
    tech2: "JavaScript",
    tech3: "Sass",
    tech4: "CSS3",
    tech5: "HTML5",
    
  },
  {
    id: 4,
    image: IMG4,
    title: "LocksPro LLC",
    github: "https://github.com/aya7abbal/LockSmithPro",
    demo: "https://www.locksprollc.net/",
    tech: "Github",
    tech2: "Bootstrap",
    tech3: "Sass",
    tech4: "CSS3",
    tech5: "HTML5",
  },
  {
    id: 7,
    image: IMG7,
    title: "Anonymous Company - Assessment",
    github: "https://github.com/aya7abbal/RisklineTask",
    tech: "Github",
    tech2: "Bootstrap",
    tech3: "Sass",
    tech4: "CSS3",
    tech5: "HTML5",
  },
  {
    id: 5,
    image: IMG5,
    title: "Food Blog - Practice Project",
    github: "https://github.com/aya7abbal/FoodBlog",tech: "Affinity Photo",
    tech2: "Github",
    tech3: "CSS3",
    tech4: "HTML5",
  },
  {
    id: 6,
    image: IMG6,
    title: "Learning - Practice Project",
    github: "https://github.com/aya7abbal/learning",
    tech: "Github",
    tech2: "Sass",
    tech3: "CSS3",
    tech4: "HTML5",
  },
  
  
];

export default dataArray;
