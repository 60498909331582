import React from "react";
import "./testimonials.css";
// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper styles
import 'swiper/css';
import { Pagination } from 'swiper/modules';



// import "swiper/swiper.min.css";
// import "swiper/modules/pagination/pagination.min.css";

import dataAvatar from "./testimonial";
const Testimonials = (props) => {
  return (
    <section id="testimonials">
      <h5>Clients'</h5>
      <h2>Testimonials</h2>
      <Swiper
        className="container testimonials__container" // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {dataAvatar.map((element) => (
          <SwiperSlide className="testimonial" key={element.id}>
            <div className="client__avatar">
              <img src={element.image} alt="element.title" />
            </div>
            <h5
              className="client_
            _name "
            >
              {element.name}
            </h5>
            <small className="client__review">{element.review}</small>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonials;
